import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEventType,
  HttpParams,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { tap } from 'rxjs/internal/operators/tap';
import { last } from 'rxjs/internal/operators/last';
import { takeUntil } from 'rxjs/operators';
import { ViewProjectService } from '../../services/view-project.service';
import { AppSettings } from '../../../appSettings/appSettings';
import { ImageSize, ImageTypeSize } from '../../models/enum/download-size';

@Injectable()
export class DownloadManagerService {
  private destroy$ = new Subject(); // todo think what to do whis this in a service
  protected ngUnsubscribe: Array<any> = [];
  private isDownloadEnabled = new BehaviorSubject<boolean>(false);
  isDownloadEnabledGlobal = this.isDownloadEnabled.asObservable();
  private downloadItems = new BehaviorSubject<Array<any>>([]);
  downloadItemsGlobal = this.downloadItems.asObservable();
  private blobURLList = new BehaviorSubject<Array<any>>([]);

  constructor(
    private http: HttpClient,
    private viewProjectService: ViewProjectService,
  ) {}

  setIsDownloadEnabled(flag: boolean): void {
    this.isDownloadEnabled.next(flag);
  }

  getDownloadItems(): Array<any> {
    return this.downloadItems.value;
  }

  setDownloadItems(data: Array<any>): void {
    this.downloadItems.next(data);
  }

  pushDownloadItem(data: any, httpParams, isWatermark?): Observable<any> {
    const getItems = this.getDownloadItems();
    const downloadItemDetails = {
      url:
        this.viewProjectService.getBaseUrl() +
        AppSettings.SINGLE_FILE_DOWNLOAD(
          data.token,
          data['locator'].replace(/\|/g, '%7C').replace(/ /g, '%20'),
        ),
      id:
        data.downloadId !== undefined && data.downloadId >= 0
          ? data.downloadId
          : getItems.length,
      downloadedPercent: 0,
      filename: data['titleName'] ? data['titleName'] : data['name'],
      fileSize: data['size'],
    };

    const index = getItems
      .map(function (e) {
        return e.id;
      })
      .indexOf(downloadItemDetails.id);

    if (index !== -1) {
      getItems.splice(index, 1);
      getItems.splice(index, 0, downloadItemDetails);
      this.downloadItems.next([...getItems]);
    } else {
      this.downloadItems.next([...getItems, downloadItemDetails]);
    }
    if (isWatermark) {
      return this.downloadSingleFile(
        downloadItemDetails.url,
        data.downloadId && data.downloadId >= 0
          ? data.downloadId
          : getItems.length,
        downloadItemDetails.fileSize,
        httpParams,
      ).pipe(
        takeUntil(this.destroy$),
        tap((res) => {
          const blobURLList = this.getBlobURLList();
          this.setBlobURLList([
            ...blobURLList,
            {
              id:
                data.downloadId && data.downloadId >= 0
                  ? data.downloadId
                  : getItems.length,
              body: res.body,
              fileSize: res.body ? res.body.size : 0,
            },
          ]);
          if (res.body) {
            this.saveDownloadForWaterMark(res);
          }
        }),
      );
    } else {
      return this.downloadFile(
        downloadItemDetails.url,
        data.downloadId >= 0 ? data.downloadId : getItems.length,
        downloadItemDetails.fileSize,
        httpParams,
      ).pipe(
        takeUntil(this.destroy$),
        tap((res) => {
          const blobURLList = this.getBlobURLList();
          this.setBlobURLList([...blobURLList, res]);
          if (res.body) {
            this.saveDownload(res);
          }
        }),
      );
    }
  }

  getBlobURLList() {
    return this.blobURLList.value;
  }

  setBlobURLList(data) {
    this.blobURLList.next(data);
  }

  getEventMessage(e: any, indexed: any, fileSize: number): any {
    if (e.type === HttpEventType.DownloadProgress) {
      // This is an download progress event. Compute and show the % done:
      const percentDone = Math.round((100 * event['loaded']) / fileSize);
      const downloadItemDetails = this.getDownloadItems().map((item, index) => {
        if (index === indexed) {
          item.downloadedPercent = percentDone;
        }
        return item;
      });
      this.setDownloadItems(downloadItemDetails);
    }
  }

  getImageItemWidth(imageType: string) {
    return {
      [ImageSize.SMALL_JPEG]: ImageTypeSize.Small,
      [ImageSize.MEDIUM_JPEG]: ImageTypeSize.Medium,
      [ImageSize.LARGE_JPEG]: ImageTypeSize.Large,
      [ImageSize.DNG]: ImageTypeSize.DNG,
      [ImageSize.TIF]: ImageTypeSize.TIF,
    }[imageType];
  }

  setHttpDownloadParams(
    ip: string,
    userId: string,
    userName: string,
    assetIds: Array<number>,
    authorityName: string,
    projectId: number,
    projectName: string,
    size: number,
    browserInfo: string,
    imageSize = '',
    isPrint: boolean,
  ) {
    const httpParams = new HttpParams()
      .append('ip', ip)
      .append('userId', userId)
      .append('userName', userName)
      // Don't include the full list of asset ids as the PDF/ZIP/single file request is authenticated and we don't want to overload the URL string
      //.append('assetIds', assetIds.join(', '))
      .append('authorityName', authorityName)
      .append('projectId', projectId)
      .append('projectName', projectName)
      .append('downloadSize', size)
      .append('browserInfo', browserInfo)
      .append('imageSize', imageSize)
      .append('isPrint', isPrint);
    return httpParams;
  }

  getEventMessageSingleDownloadWatermark(
    e: any,
    indexed: any,
    fileSize: number,
  ): any {
    if (e.type === HttpEventType.DownloadProgress) {
      // This is an download progress event. Compute and show the % done:
      const downloadItemDetails = this.getDownloadItems().map((item, index) => {
        if (index === indexed) {
          item.downloadedPercent = 100;
        }
        return item;
      });
      this.setDownloadItems(downloadItemDetails);
    }
  }

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`,
      );
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }

  downloadFile(locatore, index = 0, fileSize, httpParams): Observable<any> {
    this.ngUnsubscribe[index] = new Subject<void>();
    const url = locatore;
    const req = new HttpRequest('GET', url, {
      reportProgress: true,
      responseType: 'blob',
      params: httpParams,
    });

    return this.http.request(req).pipe(
      takeUntil(this.ngUnsubscribe[index]),
      map((res) => res),
      tap((event) => this.getEventMessage(event, index, fileSize)),
      last(),
    );
  }

  downloadSingleFile(
    locatore,
    index = 0,
    fileSize,
    httpParams,
  ): Observable<any> {
    this.ngUnsubscribe[index] = new Subject<void>();
    const url = locatore;
    const req = new HttpRequest('GET', url, {
      reportProgress: true,
      responseType: 'blob',
      params: httpParams,
    });

    return this.http.request(req).pipe(
      takeUntil(this.ngUnsubscribe[index]),
      map((res) => res),
      tap((event) =>
        this.getEventMessageSingleDownloadWatermark(event, index, fileSize),
      ),
      last(),
    );
  }

  cancelDownload(i = 0) {
    if (this.ngUnsubscribe[i]) {
      const getItems = this.getDownloadItems();
      getItems.splice(i, 1);
      this.setDownloadItems(getItems);
      // This aborts all HTTP requests.
      this.ngUnsubscribe[i].next();
      // This completes the subject properlly.
      this.ngUnsubscribe[i].complete();
      this.ngUnsubscribe = this.ngUnsubscribe.filter((item, index) => {
        if (index !== i) {
          return true;
        } else {
          return false;
        }
      });
      this.setBlobURLList(
        this.getBlobURLList().filter((item, index) => {
          if (index !== i) {
            return false;
          } else {
            return true;
          }
        }),
      );
    }
  }

  pushDownloadItemForPDF(res: any, httpParams, isWatermark?): Observable<any> {
    const data = res.t;
    const token = res.message;
    const getItems = this.getDownloadItems();
    const downloadItemDetails = {
      url:
        this.viewProjectService.getBaseUrl() +
        AppSettings.SINGLE_FILE_DOWNLOAD(
          token,
          data['locator'].replace(/\|/g, '%7C').replace(/ /g, '%20'),
        ),
      id:
        data.downloadId !== undefined && data.downloadId >= 0
          ? data.downloadId
          : getItems.length,
      downloadedPercent: 0,
      filename: data['name'],
      fileSize: data['size'],
    };

    const index = getItems
      .map(function (e) {
        return e.id;
      })
      .indexOf(downloadItemDetails.id);

    if (index !== -1) {
      getItems.splice(index, 1);
      getItems.splice(index, 0, downloadItemDetails);
      this.downloadItems.next([...getItems]);
    } else {
      this.downloadItems.next([...getItems, downloadItemDetails]);
    }

    if (isWatermark) {
      return this.downloadSingleFile(
        data['locator'].replace(/\|/g, '%7C'),
        data.downloadId && data.downloadId >= 0
          ? data.downloadId
          : getItems.length,
        downloadItemDetails.fileSize,
        httpParams,
      ).pipe(
        takeUntil(this.destroy$),
        tap((resData) => {
          const blobURLList = this.getBlobURLList();
          this.setBlobURLList([
            ...blobURLList,
            {
              id: data.downloadId >= 0 ? data.downloadId : getItems.length,
              body: resData.body,
              fileSize: resData.body ? resData.body.size : 0,
            },
          ]);
          if (resData.body) {
            this.saveDownloadForWaterMark(resData.body ? resData.body.size : 0);
          }
        }),
      );
    } else {
      return this.downloadFile(
        downloadItemDetails.url,
        data.downloadId >= 0 ? data.downloadId : getItems.length,
        downloadItemDetails.fileSize,
        httpParams,
      ).pipe(
        takeUntil(this.destroy$),
        tap((resData) => {
          const blobURLList = this.getBlobURLList();
          this.setBlobURLList([...blobURLList, resData]);
          if (resData.body) {
            this.saveDownload(resData);
          }
        }),
      );
    }
  }

  saveDownload(fileSize: any) {
    fileSize.url = fileSize.url.slice(0, fileSize.url.indexOf('?'));

    const selectElementFromDownloadItems = this.getDownloadItems().filter(
      (item) => item.url === fileSize.url,
    )[0];
    const ext = selectElementFromDownloadItems.filename.substring(
      selectElementFromDownloadItems.filename.lastIndexOf('.'),
      selectElementFromDownloadItems.filename.length,
    );
    if (selectElementFromDownloadItems) {
      const url = window.URL.createObjectURL(fileSize.body);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = selectElementFromDownloadItems.filename.replace(ext, ext);
      a.click();
      window.URL.revokeObjectURL(url);
      if (a) {
        a.remove();
      }
    }

    const downloadItemsObj = this.getDownloadItems();
    if (downloadItemsObj.length > 0) {
      let downloadRunning = false;
      downloadItemsObj.forEach((element) => {
        if (element.downloadedPercent < 100) {
          downloadRunning = true;
        }
      });
      if (!downloadRunning) {
        this.setIsDownloadEnabled(false);
        this.setDownloadItems([]);
      }
    }
  }

  saveDownloadForWaterMark(fileSize: any) {
    fileSize.url = fileSize.url.slice(0, fileSize.url.indexOf('?'));

    const selectElement = this.getBlobURLList().filter(
      (item) => item.fileSize === fileSize.body.size,
    )[0];
    const selectElementFromDownloadItems =
      this.getDownloadItems()[this.getDownloadItems().length - 1];
    const ext = selectElementFromDownloadItems.filename.substring(
      selectElementFromDownloadItems.filename.lastIndexOf('.'),
      selectElementFromDownloadItems.filename.length,
    );
    const newext = ext;
    if (selectElement && selectElementFromDownloadItems && selectElement.body) {
      const url = window.URL.createObjectURL(selectElement.body);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = selectElementFromDownloadItems.filename.replace(ext, newext);
      a.click();
      window.URL.revokeObjectURL(url);
      if (a) {
        a.remove();
      }
    }
    const downloadItemsObj = this.getDownloadItems();
    if (downloadItemsObj.length > 0) {
      let downloadRunning = false;
      downloadItemsObj.forEach((element) => {
        if (element.downloadedPercent < 100) {
          downloadRunning = true;
        }
      });
      if (!downloadRunning) {
        this.setIsDownloadEnabled(false);
        this.setDownloadItems([]);
      }
    }
  }
}
